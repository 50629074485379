import React from "react";
import { default as _get } from "lodash/get";
import { default as _shuffle } from "lodash/shuffle";

import { graphql } from "gatsby";
import { ArticleJsonLd } from "gatsby-plugin-next-seo";

import ContentfulArticleHeader from "../contentful/ContentfulArticleHeader";
import ContentfulBlogTeaser from "../contentful/ContentfulBlogTeaser";
import ContentfulHeroImage from "../contentful/ContentfulIHeroImage";
import ContentfulSeo from "../contentful/ContentfulSeo";
import ContentfulBreadcrumbs, {
  parseBreadcrumbsDataBlogPost,
} from "../contentful/ContentfulBreadcrumbs";

import { mapNodesToPosts, parseSections } from "../contentful/helper";
import { getUrl } from "../helper/uri";

export const query = graphql`
  query (
    $id: String!
    $categoryId: String
    $relatedEntriesExcludeIds: [String]
  ) {
    contentfulBlogPost(id: { eq: $id }) {
      id
      template
      heroImagePosition
      heroImage {
        ...FragmentHeroImage
      }
      # used for position above of hero image
      heroImageStage: heroImage {
        ...FragmentHeroImageStage
      }
      author {
        name
      }
      title
      subline {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      slug
      categories {
        title
        titleShort
        id
        slug
      }
      composeSeo {
        seoTitle
      }
      publishDate(formatString: "DD.MM.YYYY")
      updatedAt(formatString: "DD.MM.YYYY")
      intro {
        intro
      }
      ...BlogPostComposeSeo
      sections {
        ...FragmentContentfulImage
        ...FragmentContentfulImageText
        ...FragmentContentfulLocationFinder
        ...FragmentContentfulRichText
        ...FragmentContentfulSliderCards
        ...FragmentContentfulTeaserBig
        ...FragmentContentfulTeaserGroup
        ...FragmentContentfulVideo
        # ...FragmentContentfulStageVideo
      }
      relatedEntriesMode
      relatedEntries {
        ...TeaserBlogPost
      }
    }
    breadcrumbs: contentfulBlogPost(id: { eq: $id }) {
      # Breadcrumbs
      ...FragmentBreadcrumbsBlogPost
    }

    # Related Posts/Posts of Category
    # Get related posts automatically, will be combined with relatedEntries of Blog Post and fill up the related posts section to have at least 3 posts (as long as there are minimum 3 posts in this category)
    allContentfulBlogPost(
      filter: {
        id: { nin: $relatedEntriesExcludeIds }
        categories: { elemMatch: { id: { eq: $categoryId } } }
      }
      sort: { fields: updatedAt, order: DESC }
    ) {
      edges {
        node {
          ...TeaserBlogPost
        }
      }
    }
  }
`;

export type BlogPostTemplate = "default" | "minimal";

export const ArticleTemplate = props => {
  // base variables
  const blogPost = _get(props, "data.contentfulBlogPost");
  const title = _get(blogPost, "title", undefined);
  const slug = _get(blogPost, "slug", undefined);
  const category = _get(blogPost, "categories[0].titleShort", null);
  const categorySlug = _get(blogPost, "categories[0].slug", null);
  // const blogPostsOfCategory = _get(props, "data.allContentfulBlogPost");
  // console.log(blogPostsOfCategory);

  // TODO: share with category-page
  const relatedEntriesMode = _get(blogPost, "relatedEntriesMode", "default");
  const relatedEntriesOfPost = _get(blogPost, "relatedEntries", []);
  const relatedPostsWithinCategory = mapNodesToPosts(
    _get(props, "data.allContentfulBlogPost.edges", [])
  );
  const relatedPosts = selectRelatedPosts(
    relatedEntriesOfPost,
    relatedPostsWithinCategory
  );

  // seo variables
  const composeSeo = _get(blogPost, "composeSeo", {});
  const seoTitle = _get(blogPost, "composeSeo.seoTitle", null);

  // content variables
  const content = {
    title: _get(blogPost, "title"),
    subline: _get(
      blogPost,
      "subline.childMarkdownRemark.rawMarkdownBody",
      null
    ),
    intro: _get(blogPost, "intro.intro", null),
    heroImage: _get(blogPost, "heroImage", undefined),
    heroImageIsAbove:
      _get(blogPost, "heroImagePosition", undefined) === "above",
    heroImageStage: _get(blogPost, "heroImageStage", undefined),
    meta: {
      children: _get(blogPost, "categories", null),
      date: _get(blogPost, "publishDate", null),
      duration: 4, // TODO: collect reading times from sections
    },
    sections: _get(blogPost, "sections", []),
  };

  // template variables
  const template: BlogPostTemplate = _get(blogPost, "template", undefined);
  const templateIsMinimal: boolean = template === "minimal";
  return (
    <article>
      <ArticleJsonLd
        url={getUrl(_get(blogPost, "slug", ""))}
        headline={_get(blogPost, "title")}
        // TODO: image integration
        // images={[
        //   "https://example.com/photos/1x1/photo.jpg",
        //   "https://example.com/photos/4x3/photo.jpg",
        //   "https://example.com/photos/16x9/photo.jpg",
        // ]}
        datePublished={_get(blogPost, "publishDate", null)}
        dateModified={_get(blogPost, "updatedAt", null)}
        // TODO: Author
        // authorName={_get(blogPost, "author.name", null)}
        // TODO: set in gatsby-config (or otherwise shareable)
        //publisherName=""
        //publisherLogo="https://www.example.com/photos/logo.jpg"
        description={_get(composeSeo, "description", undefined)}
      />

      <ContentfulSeo title={title} composeSeo={composeSeo} />

      <ContentfulBreadcrumbs
        items={parseBreadcrumbsDataBlogPost(
          _get(props, "data.breadcrumbs", [])
        )}
      />

      {content.heroImageIsAbove === true ? (
        <ContentfulHeroImage
          content={content.heroImageStage}
          disableMarginTop
        />
      ) : null}

      <ContentfulArticleHeader
        headline={content.title}
        subline={content.subline}
        intro={content.intro}
        meta={templateIsMinimal ? undefined : content.meta}
      />

      {content.heroImageIsAbove !== true ? (
        <ContentfulHeroImage content={content.heroImage} />
      ) : null}

      {parseSections(
        content.sections,
        _get(props, "pageContext.locations", [])
      )}

      {relatedEntriesMode === "hide" ? null : (
        <ContentfulBlogTeaser posts={relatedPosts} />
      )}
    </article>
  );
};

/**
 *
 * @param {array} entries The related entries of the post
 * @param {array} posts The related posts found within the category of the post
 * @param {int} maxPosts Select up to maxPosts of both entry arrays
 *
 * @returns Array of posts with up to maxPosts
 */
function selectRelatedPosts(entries = [], posts = [], maxPosts = 3) {
  const hasRelatedEntries = Array.isArray(entries) && entries.length > 0;
  const relatedEntries = hasRelatedEntries ? entries : [];
  const relatedPostsMax = hasRelatedEntries
    ? maxPosts - relatedEntries.length
    : maxPosts;
  const hasRelatedPosts = Array.isArray(posts) && posts.length > 0;
  const relatedPosts = hasRelatedPosts
    ? _shuffle(posts).slice(0, relatedPostsMax)
    : [];

  return [...relatedEntries, ...relatedPosts];
}

export default ArticleTemplate;
